import GenericTextField from '@/components/generic/GenericTextField';
import styles from '@/styles/Login/Login.module.scss';
import { Alert, Button, CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

const LoginPage = () => {

	const router = useRouter();

	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [loginError, setLoginError] = useState<boolean>(false);

	const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

	const login = () => {
		if (email.length > 0 && password.length > 0) {
			setLoginInProgress(true);
			fetch(`/api/auth/login`, {
				method: 'POST',
				body: JSON.stringify({
					email: email,
					password: password
				})
			}).then(res => res.json()).then((data_res) => {
				console.log("data_res", data_res);
				if (data_res?.status === 'ok') {
					router.push("/");
					setLoginError(false);
					return true;
				} else {
					setLoginInProgress(false);
					setLoginError(true);
					return false;
				}
			});
		} else {
			setLoginError(true);
		}
	}

	return (
		<div className={styles.login_page_holder}>
			<div className={styles.form_holder}>
				<h1>Login to ERP</h1>
				<GenericTextField
					label='Email'
					value={email}
					onChange={(e: any) => {
						setEmail(e.target.value);
					}}
					className={styles.input_field}
					onKeyPress={(e: any) => {
						if (e.keyCode === 13) {
							login();
						}
					}}
				/>
				<GenericTextField
					label='Password'
					value={password}
					onChange={(e: any) => {
						setPassword(e.target.value);
					}}
					className={styles.input_field}
					password
					onKeyPress={(e: any) => {
						if (e.keyCode === 13) {
							login();
						}
					}}
				/>
				{loginInProgress ?
					<CircularProgress />
					: null}
				{loginError ?
					<div className={styles.error_alert}>
						<Alert severity='error'>Chybné přihlašovací údaje. Zkuste to prosím znovu.</Alert>
					</div>
					: null}
				<Button
					variant={`outlined`}
					onClick={() => {
						login();
					}}
					disabled={loginInProgress}
				>Přihlásit se</Button>
			</div>
		</div>
	)
}

export default LoginPage;